<template>
    <div id="app">
        <tree-view />
    </div>
</template>

<script>
import TreeView from './components/TreeView.vue';

export default {
    name: 'App',
    components: {
        TreeView,
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
