<template>
    <div class="filter-box">
        <input
            type="text"
            v-model="filterText"
            @input="validateAndEmitFilterText"
            placeholder="search"
        />
    </div>
</template>

<script>
export default {
    name: 'FilterBox',
    data() {
        return {
            filterText: '',
        };
    },
    methods: {
        validateAndEmitFilterText() {
            const sanitizedText = this.filterText.replace(/['"<>[](){}]/g, '');
            if (sanitizedText !== this.filterText) {
                this.filterText = sanitizedText;
            }
            this.$emit('filter-changed', this.filterText);
        },
    },
};
</script>

<style scoped>
.filter-box {
    margin-bottom: 10px;
}
.filter-box input {
    background-color: black;
    color: white;
    font-family: monospace;
    border: 1px solid green;
}
</style>
