<template>
    <div class="tree-view">
        <filter-box @filter-changed="filterContent" />
        <tree-item
            v-for="(item, index) in filteredContent"
            :key="index"
            :item="item"
            :expandAll="filterText != ''"
            :filterText="filterText"
            :depth="0"
        />
    </div>
</template>

<script>
import axios from 'axios';
import TreeItem from './TreeItem.vue';
import FilterBox from './FilterBox.vue';

export default {
    name: 'TreeView',
    components: {
        TreeItem,
        FilterBox,
    },
    data() {
        return {
            content: [],
            filterText: '',
        };
    },
    computed: {
        filteredContent() {
            if (!this.filterText) {
                return this.content;
            }
            return this.filterItems(this.content);
        },
    },
    methods: {
        filterContent(filterText) {
            this.filterText = filterText.toLowerCase();
        },
        filterItems(items) {
            let res = [];
            for (let item of items) {
                if (
                    (item.text && item.text.toLowerCase().includes(this.filterText)) ||
                    (item.title && item.title.toLowerCase().includes(this.filterText))
                ) {
                    res.push(item);
                } else if (item.content) {
                    let children = this.filterItems(item.content);
                    if (children.length > 0) {
                        res.push({
                            ...item,
                            content: children,
                        });
                    }
                }
            }
            return res;
        },
    },
    mounted() {
        axios.get('content.json').then((response) => {
            this.content = response.data.content;
        });
    },
};
</script>

<style scoped>
.tree-view {
    font-family: monospace;
    color: green;
    text-align: left;
    padding-left: 10px;
}
</style>
