<template>
    <div class="tree-item">
        <div
            @click="toggle"
            :class="{ 'tree-item-title': true, 'tree-item-leaf': isLeaf }"
            :style="{ paddingLeft: depth * 15 + 'px' }"
        >
            <span v-html="getTitleHtml"></span>
        </div>
        <template v-if="isLeaf">
            <div
                v-if="item.text"
                class="tree-item-content"
                :style="{ paddingLeft: (depth + 1) * 15 + 'px' }"
                v-html="getTextHtml"
            ></div>
        </template>
        <div v-if="!isLeaf" v-show="isVisible" class="tree-item-children">
            <tree-item
                v-for="(child, index) in itemContent"
                :key="index"
                :item="child"
                :expandAll="expandAll"
                :filterText="filterText"
                :depth="depth + 1"
            />
        </div>
    </div>
</template>

<script>
import showdown from 'showdown';
showdown.setOption('tables', true);
showdown.setOption('backslashEscapesHTMLTags', true);
var md2html = new showdown.Converter();

export default {
    name: 'TreeItem',
    props: {
        item: Object,
        expandAll: Boolean,
        filterText: String,
        depth: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            expanded: false,
        };
    },
    computed: {
        isLeaf() {
            return this.item.text;
        },
        isExpanded() {
            return this.expandAll || this.expanded;
        },
        prefix() {
            if (this.item.pinned) {
                return '*';
            }
            return this.isExpanded ? '-' : '+';
        },
        isVisible() {
            return this.isExpanded || this.item.pinned;
        },
        itemText() {
            if (this.filterText !== '') {
                return this.item.text
                    .toLowerCase()
                    .replace(
                        this.filterText,
                        `<span style="color: yellow;">${this.filterText}</span>`
                    );
            }
            return this.item.text;
        },
        itemTitle() {
            let res = '';
            if (!this.isLeaf) {
                res = '`' + this.prefix + '` ';
            }
            if (!this.item.title) {
                res += '';
            } else if (this.filterText !== '') {
                res += this.item.title
                    .toLowerCase()
                    .replace(
                        this.filterText,
                        `<span style="background-color: yellow;">${this.filterText}</span>`
                    );
            } else res += this.item.title;
            return res;
        },
        getTextHtml() {
            return md2html.makeHtml(this.itemText);
        },
        getTitleHtml() {
            return md2html.makeHtml(this.itemTitle);
        },
        itemContent() {
            return this.item.content?.filter((child) => !child.hidden) ?? [];
        },
    },
    methods: {
        toggle() {
            if (!this.isLeaf) {
                this.expanded = !this.expanded;
            }
        },
    },
};
</script>

<style>
.tree-item {
    font-family: monospace;
    font-size: 15px;
}
.tree-item-title {
    cursor: pointer;
    color: green;
}
.tree-item-content {
    margin-left: 15px;
    color: white;
    white-space: pre-wrap;
}
.tree-item-content table {
    border-collapse: collapse;
}
.tree-item-content table td,
.tree-item-content table th {
    border: 1px dotted rgb(70, 70, 70);
}
.tree-item-content table tr:first-child th {
    border-top: 0;
}
.tree-item-content table tr:last-child td {
    border-bottom: 0;
}
.tree-item-content table tr td:first-child,
.tree-item-content table tr th:first-child {
    border-left: 0;
}
.tree-item-content table tr td:last-child,
.tree-item-content table tr th:last-child {
    border-right: 0;
}
.tree-item-leaf {
    cursor: default;
}
</style>
